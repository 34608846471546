<template>
  <v-select
      v-model="localValue"
      :items="countries"
      outlined
      dense
      single-line
      :disabled="disabled"
      :multiple="multiple"
      :rules="rules"
      :label="label"
      :hide-details="hideDetails"
  >
    <template v-slot:selection="{ item }">
      <country-flag :country="item" class="mb-0 mr-1"/>
    </template>
    <template v-slot:item="{ item }">
      <country-flag :country="item" class="mb-0 mr-2"/>
      <span class="text-subtitle-2">
        {{ item }}
      </span>
    </template>

  </v-select>
</template>
<script>
import CountryFlag from 'vue-country-flag'
import { mapGetters } from 'vuex'

export default {
  name: 'CountryFlagSelect',
  components: { CountryFlag },
  props: {
    value: {
      type: [String, Array],
      required: true
    },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    },
    hideDetails: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries'
    }),
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
