<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="updateForm" v-model="valid" :disabled="submitLoading">
          <v-col cols="10">
            <p class="font-weight-bold">
              {{ $t('issueRegister.documents') }}: {{ document.type | getDescription(documentTypes) }}
            </p>
            <label>{{ $t("companiesDetails.name") }}</label>
            <v-text-field
              v-model="document.description"
              outlined
              dense
              disabled
              :rules="required"
            ></v-text-field>
            <v-radio-group
              v-if="document.type !== OTHER"
              v-model="document.validIndefinitely"
              disabled
            >
              <template v-slot:label>
                <span>{{ $t("companiesDetails.date") }}</span>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <span>{{ $t("companiesDetails.indefinitely") }}</span>
                </template>
              </v-radio>
              <v-radio :value="false">
                <template v-slot:label>
                  <v-menu
                    v-model="validToMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="validTo"
                        label="data"
                        append-icon="mdi-calendar"
                        readonly
                        dense
                        outlined
                        single-line
                        hide-details
                        :disabled="
                          document.validIndefinitely ||
                          document.validIndefinitely === null
                        "
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="validTo"
                      :first-day-of-week="1"
                      @input="validToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="10">
            <div class="d-flex flex-column">
              <div
                v-for="file in document.files"
                :key="file.name"
                class="d-flex align-center mb-8"
              >
                <h4>{{ fileName(file.name) }}</h4>
                <v-icon
                  color="blue"
                  class="ml-auto pointer"
                  small
                  @click="getFile(file.name)"
                  >mdi-download</v-icon
                >
              </div>
              <div>
                <v-btn
                  color="primary"
                  :disabled="loading"
                  @click="removeDocument"
                >
                  {{ $t("companiesDetails.delete") }}
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col v-if="!document.validIndefinitely && document.type !== CERTIFICATE" cols="12">
            <label>{{ $t("companiesDetails.reminder") }}</label>
            <v-checkbox
              :input-value="document.notificationMothBefore"
              hide-details
              readonly
              disabled
              :label="$t('companiesDetails.month')"
              @change="updateDocument"
            ></v-checkbox>
            <v-checkbox
              :input-value="document.notificationWeekBefore"
              hide-details
              readonly
              disabled
              :label="$t('companiesDetails.week')"
              @change="updateDocument"
            ></v-checkbox>
            <v-checkbox
              :input-value="document.notificationDayBefore"
              hide-details
              readonly
              disabled
              :label="$t('companiesDetails.day')"
              @change="updateDocument"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <div>
                <v-checkbox
                  v-model="document.verified"
                  :label="$t('companiesDetails.verified')"
                  @change="verifyDocument"
                  class="mt-0"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import { dateFormat2 } from '@/utils/helpers.js'
import { CERTIFICATE, OTHER } from '@/types/document-types'
export default {
  name: 'Document',
  props: ['companyId', 'document', 'loading', 'disabled'],
  data () {
    return {
      validToMenu: false,
      valid: true,
      submitLoading: false,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      CERTIFICATE,
      OTHER
    }
  },
  computed: {
    documentTypes () {
      return this.$store.getters.enums.documentTypes
    },
    validTo: {
      get () {
        return dateFormat2(this.document.validTo)
      },
      set (newValue) {
        this.document.validTo = newValue
      }
    }
  },
  watch: {
    'document.validIndefinitely': function (val) {
      if (val) {
        this.document.notificationMothBefore = false
        this.document.notificationWeekBefore = false
        this.document.notificationDayBefore = false
      }
    }
  },
  methods: {
    fileName (name) {
      var length = name.length
      if (length > 40) {
        return name.slice(0, 20) + '...' + name.slice(length - 10, length)
      }
      return name
    },
    verifyDocument () {
      this.$emit('verify', {
        companyId: this.companyId,
        documentId: this.document.id,
        verified: this.document.verified
      })
    },
    updateDocument (document) {
      this.$refs.updateForm.validate()
      if (!this.valid) {
        return
      }

      this.$emit('update', {
        companyId: this.companyId,
        document: {
          id: document.id,
          description: document.description,
          validIndefinitely: document.validIndefinitely,
          validTo: document.validTo,
          notificationMothBefore: document.notificationMothBefore,
          notificationWeekBefore: document.notificationWeekBefore,
          notificationDayBefore: document.notificationDayBefore
        }
      })
    },
    removeDocument () {
      this.$emit('remove', {
        companyId: this.companyId,
        documentId: this.document.id
      })
    },
    reject () {
      this.$emit('reject')
      this.$refs.updateCommentForm.resetValidation()
    },
    submit () {
      this.$refs.updateCommentForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit')
      this.$refs.updateCommentForm.resetValidation()
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile(
          '/CustomerService/GetCompanyDocumentFile',
          {
            companyId: this.companyId,
            fileName: file
          }
        )

        const blob = new Blob([data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {}
    }
  }
}
</script>
