<template>
  <div>
    <v-card v-if="issue" class="pa-5">
      <v-chip v-if="issue.closed" label class="ma-2" color="primary">
        {{ $t('issueRegister.closed') }}
      </v-chip>
      <v-card-title
        ><span class="red--text pr-1" v-if="issue.reRegistration">{{ $t('issueRegister.reRegistration') }}</span>{{ $t('issueRegister.title') }}<v-spacer></v-spacer>
        <div v-if="!issue.closed">
          <v-btn
            outlined
            :disabled="
              !($permission('CompanyAcceptAndRejectAll') ||
              ($permission('CompanyAcceptAndReject') && $store.getters.userCommonData.id === issue.currentlyHandledBy ))
            "
            class="mb-2 mr-2"
            @click="rejectDialog = true"
          >
            {{ $t('common.reject') }}
          </v-btn>
          <v-btn
            :disabled="
              !($permission('CompanyAcceptAndRejectAll') ||
              ($permission('CompanyAcceptAndReject') && $store.getters.userCommonData.id === issue.currentlyHandledBy )) ||
              loading
            "
            color="primary"
            class="mb-2"
            :loading="loading"
            @click="accept"
          >
            {{ $t('issueRegister.accept') }}
          </v-btn>
        </div></v-card-title
      >
      <v-card-subtitle>
        <div class="d-flex flex-column">
          <span
            >{{ $t('issueRegister.created') }}: {{ issue.dateOfRegistration | dateFormat }}</span
          >
          <span>{{ $t('issueRegister.id') }}: {{ issue.displayId }}</span>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8" lg="8">
            <v-row v-if="comments" class="mb-8">
              <v-col md="12">
                <Comments
                  :comments="comments"
                  :disabled="issue.closed"
                  @comment-saved="sendComment"
                /></v-col>
            </v-row>
            <v-card>
              <v-card-text>
                <v-col md="12" lg="10">
                  <p class="font-weight-bold">{{ $t('issueRegister.companyData') }}</p>
                  <v-form
                    ref="updateProfileForm"
                    v-model="valid"
                    :disabled="submitLoading"
                  >
                    <label>{{ $t('issueRegister.vat')}}</label>
                    <div class="d-flex">
                      <v-text-field
                        v-model="issue.company.vatNumber"
                        class="mr-8"
                        outlined
                        dense
                        :disabled="issue.closed"
                        :rules="required"
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        class="mb-2"
                        :disabled="issue.closed || loadingNIP24"
                        :loading="loadingNIP24"
                        @click="verify"
                      >
                        {{ $t('issueRegister.verifyInNip24')}}
                      </v-btn>
                    </div>
                    <label>{{ $t('issueRegister.companyName')}}</label>
                    <v-text-field
                      v-model="issue.company.name"
                      outlined
                      dense
                      :disabled="issue.closed"
                      :rules="required"
                    ></v-text-field>
                    <label>{{ $t('issueRegister.streetAndNumber') }}</label>
                    <v-text-field
                      v-model="issue.company.street"
                      outlined
                      dense
                      :disabled="issue.closed"
                      :rules="required"
                    ></v-text-field>
                    <label>{{ $t('issueRegister.zipCode') }}</label>
                    <v-text-field
                      v-model="issue.company.zipCode"
                      outlined
                      dense
                      :disabled="issue.closed"
                      :rules="[...required, maxCharInZipCode]"
                    ></v-text-field>
                    <label>{{ $t('issueRegister.city') }}</label>
                    <v-text-field
                      v-model="issue.company.city"
                      outlined
                      dense
                      :disabled="issue.closed"
                      :rules="required"
                    ></v-text-field>
                    <v-row>
                      <v-col sm="3" md="3" lg="3" xl="2">
                        <label>{{ $t('issueRegister.country') }}</label>
                        <CountryFlagSelect
                          v-model="issue.company.country"
                          :disabled="issue.closed"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <div class="d-flex">
                    <v-btn
                      v-if="!issue.closed"
                      color="primary"
                      class="ml-auto"
                      :disabled="submitLoading || issue.closed"
                      :loading="submitLoading"
                      @click="updateCompanyData"
                    >
                      {{ $t("common.update") }}
                    </v-btn>
                  </div>
                </v-col>
              </v-card-text>
            </v-card>
            <v-card class="mt-8">
              <v-card-text>
                <v-col md="12" lg="10">
                  <p class="font-weight-bold">{{ $t('companiesDetails.contact') }}</p>
                  <label>{{ $t('companies.employee.name') }}</label>
                  <v-text-field
                    :value="issue.companyUser.name"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.surname') }}</label>
                  <v-text-field
                    :value="issue.companyUser.surname"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.ownerPhone') }}</label>
                  <v-text-field
                    :value="issue.companyUser.phone"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.email') }}</label>
                  <v-text-field
                    :value="issue.companyUser.email"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.language') }}</label>
                  <v-select
                    :value="issue.companyUser.language"
                    :items="languages"
                    item-text="label"
                    item-value="value"
                    dense
                    outlined
                    disabled
                  ></v-select>
                </v-col>
              </v-card-text>
            </v-card>
            <v-row v-for="(doc, i) in documents" :key="i" class="mt-4">
              <v-col v-for="document in doc" :key="document.id" cols="12">
                <Document
                  :document="document"
                  :companyId="issue.company.id"
                  @update="documentUpdate"
                  @verify="documentVerify"
                  @remove="documentRemove"
                ></Document>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-col cols="10">
                      <p class="font-weight-bold">{{ $t('issueRegister.add') }}</p>
                      <label>{{ $t('issueRegister.documentType') }}</label>
                      <v-select
                        :label="$t('issueRegister.selectDocumentType')"
                        v-model="addDocumentType"
                        :items="documentTypes"
                        item-text="description"
                        item-value="id"
                        dense
                        outlined
                        clearable
                      ></v-select>
                      <DocumentNew v-if="addDocumentType" :type="addDocumentType" :loading="loadingFiles" @submit="addFile" />
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              ><v-col>
                <v-card class="mt-8">
                  <v-card-text>
                    <v-col md="12" lg="10">
                      <p class="font-weight-bold">{{ $t('issueRegister.consent') }}</p>
                      <v-container>
                        <v-row align="center" justify="center">
                          <v-col cols="1">
                            <v-checkbox
                              style="margin-top: -7px;"
                              input-value="true"
                              disabled
                              hide-details
                            />
                          </v-col>
                          <v-col>
                            <div @click.stop v-html="$t('register.step4.termsOfUse')"></div>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                          <v-col cols="1">
                            <v-checkbox
                              style="margin-top: -7px;"
                              input-value="true"
                              disabled
                              hide-details
                            />
                          </v-col>
                          <v-col>
                            <div @click.stop v-html="$t('register.step4.privacyPolicy')"></div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-card-text> </v-card
              ></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" offset-lg="1" md="4" lg="3">
            <Checklist
              v-if="!issue.closed && issue.checkList.length"
              :issueId="issue.id"
              :checklist="issue.checkList"
              :update="updateCheckList"
              class="mb-8"
              @checklist-updated="updateChecklist"
            ></Checklist>
            <div>
              <ChangeDepartment
                :value="issue.department"
                register
                :closed="issue.closed"
                @department-changed="changeDepartment"
              ></ChangeDepartment>
            </div>
            <div>
              <ChangeState
                :issueState="issue.issueState"
                :companyId="issue.company.id"
                :department="issue.department"
                :closed="issue.closed"
                @user-resigned="updateState"
              ></ChangeState>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <RejectIssue
      :dialog="rejectDialog"
      :loading="loadingDialog"
      @close="rejectDialogClose"
      @submit="rejectDialogSubmit"
    >
    </RejectIssue>
  </div>
</template>
<script>
import Service from '@/services'
import { downloadFile, maxCharInZipCode } from '@/utils/helpers'
import * as documentType from '@/types/document-types'
import Document from './Documents/Document.vue'
import Comments from '@/components/comments/Comments.vue'
import Checklist from './Checklist/Checklist.vue'
import RejectIssue from './Dialogs/RejectIssue.vue'
import ChangeState from '../../components/issue/ChangeState.vue'
import ChangeDepartment from '@/components/issue/ChangeDepartment'
import CountryFlagSelect from '@/components/CountryFlagSelect'
import DocumentNew from '../Companies/Market/CompanyDetails/Tabs/Tabs/DocumentNew.vue'

export default {
  name: 'RegisterIssue',
  components: {
    Document,
    Comments,
    Checklist,
    RejectIssue,
    ChangeState,
    ChangeDepartment,
    CountryFlagSelect,
    DocumentNew
  },
  data () {
    return {
      loading: false,
      submitLoading: false,
      moveLoading: false,
      loadingFiles: false,
      loadingDialog: false,
      loadingNIP24: false,
      valid: true,
      languages: [],
      issue: null,
      comments: null,
      rejectDialog: false,
      documents: {
        policy: null,
        licence: null,
        certificate: null,
        other: null
      },
      targetDeparment: null,
      userDepartment: null,
      usersToAssign: null,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      maxCharInZipCode,
      updateCheckList: false,
      addDocumentType: null
    }
  },
  async created () {
    await this.fetchLanguages()
    await this.getIssueDetails()
    await this.getComments()
    const { data: usersToAssign } = await Service.get('/Issue/GetUsersToAssign')
    this.usersToAssign = usersToAssign
    const { data: userDepartment } = await Service.get(
      '/Issue/GetUserDepartment'
    )
    this.userDepartment = userDepartment
  },
  computed: {
    documentTypes () {
      return this.$store.getters.enums.documentTypes
    },
    departmentTypes () {
      return this.$store.getters.enums.departmentTypes.filter(
        (type) => type.id !== this.issue.department
      )
    }
  },
  methods: {
    async fetchLanguages () {
      const { data } = await Service.get('Frontend/GetAvailableLanguages')
      this.lang = data
      this.languages = data.map((language) => {
        return {
          value: language,
          label: this.$t(`languages.${language}`)
        }
      })
    },
    async getIssueDetails () {
      this.loading = true
      try {
        const { data } = await Service.get(
          '/Issue/GetRegisterCompanyIssueDetails',
          {
            issueId: this.$route.params.id
          }
        )
        this.issue = data
      } catch (error) {}

      await this.getDocuments()
      this.loading = false
    },
    async getComments () {
      this.loading = true
      try {
        const { data } = await Service.get('/Issue/GetComments', {
          issueId: this.issue.id
        })
        this.comments = data
      } catch (error) {}

      this.loading = false
    },
    async getDocuments () {
      await this.getPolicy()
      await this.getCertificate()
      await this.getLicence()
      await this.getOther()
    },
    async getPolicy () {
      this.loading = true
      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocuments',
          {
            companyId: this.issue.company.id,
            documentType: documentType.POLICY
          }
        )
        this.documents.policy = data
      } catch (error) {}

      this.loading = false
    },
    async getLicence () {
      this.loading = true
      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocuments',
          {
            companyId: this.issue.company.id,
            documentType: documentType.LICENCE
          }
        )
        this.documents.licence = data
      } catch (error) {}

      this.loading = false
    },
    async getCertificate () {
      this.loading = true
      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocuments',
          {
            companyId: this.issue.company.id,
            documentType: documentType.CERTIFICATE
          }
        )
        this.documents.certificate = data
      } catch (error) {}

      this.loading = false
    },
    async getOther () {
      this.loading = true
      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocuments',
          {
            companyId: this.issue.company.id,
            documentType: documentType.OTHER
          }
        )
        this.documents.other = data
      } catch (error) {}

      this.loading = false
    },
    async sendComment (comment) {
      try {
        await Service.post('/Issue/AddCommentCommand', {
          issueId: this.issue.id,
          text: comment
        })
        await this.getComments()
        this.comment = null
      } catch (error) {}
    },
    async updateCompanyData () {
      this.$refs.updateProfileForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      this.loading = true

      try {
        await Service.put('/CustomerService/UpdateCompanyData', {
          companyId: this.issue.company.id,
          vatNumber: this.issue.company.vatNumber,
          name: this.issue.company.name,
          street: this.issue.company.street,
          zipCode: this.issue.company.zipCode,
          city: this.issue.company.city,
          country: this.issue.company.country
        })
        this.$refs.updateProfileForm.resetValidation()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.updateProfile')
        )
      } catch (error) {}
      this.submitLoading = false
      this.loading = false
    },
    async documentVerify (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/MarkCompanyDocument', document)
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.verify')
        )
      } catch (e) {}

      this.loading = false
    },
    async removeFile (file) {
      await this.documentRemove({
        companyId: this.issue.company.id,
        documentId: file.id
      })
    },
    async documentUpdate (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/UpdateCompanyDocument', document)
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.update')
        )
      } catch (e) {}

      this.loading = false
    },
    async documentRemove (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/ArchiveCompanyDocument', document)
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.remove')
        )
        this.update()
      } catch (e) {}

      this.loading = false
    },
    async addFile (document) {
      this.loadingFiles = true

      const formData = new FormData()
      formData.append('CompanyId', this.issue.company.id)
      for (const property in document) {
        if (property === 'files') {
          document[property].forEach(file => {
            formData.append('Documents[0].FilesWrapper.Files', file, file.name)
          })
        } else {
          formData.append(`Documents[0].${property}`, document[property])
        }
      }

      try {
        await Service.post('/CustomerService/UploadCompanyDocument', formData)
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('issueRegister.added')
        )
        this.addDocumentType = null
        await this.getDocuments()
      } catch (error) {}

      this.loadingFiles = false
    },
    rejectDialogClose () {
      this.rejectDialog = false
    },
    async rejectDialogSubmit (reason) {
      this.loadingDialog = true
      try {
        await Service.post('Issue/RejectCompany', {
          issueId: this.issue.id,
          reason: reason
        })
        this.rejectDialog = false

        await this.$router.push({ name: 'CompaniesIssuesCustomerService', params: { activeTab: '2' } })

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('issueRegister.companyRejected')
        )
      } catch (error) {}
      this.loadingDialog = false
    },
    async accept () {
      this.loading = true
      try {
        await Service.post('Issue/AcceptCompany', {
          issueId: this.issue.id
        })

        await this.$router.push({ name: 'CompaniesCustomerService', params: { activeTab: '0' } })

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('issueRegister.companyAccepted')
        )
      } catch (error) {}
      this.loading = false
    },
    async verify () {
      this.loadingNIP24 = true
      try {
        const { data } = await Service.get('/ExternalApi/GetVatStatus', {
          VatEu: this.issue.company.vatNumber
        })

        await this.$store.dispatch(
          'setSuccessNotification', data
        )
      } catch (error) {
      } finally {
        this.loadingNIP24 = false
      }
    },
    updateChecklist (checklist) {
      this.issue.checkList = checklist
    },
    changeDepartment (department) {
      this.issue.department = department
      this.updateCheckList = !this.updateCheckList
    },
    async updateState () {
      try {
        const { data: issueState } = await Service.get('Issue/GetIssueState', {
          issueId: this.$route.params.id
        })
        this.issue.issueState = issueState
      } catch (e) {}
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile('/CustomerService/GetCompanyDocumentFile', {
          companyId: this.issue.company.id,
          fileName: file.files[0].name
        })
        downloadFile(file.files[0], data)
      } catch (e) {}
    }
  }
}
</script>
